.logo {
  height: 32px;
  margin: 16px;
  text-align: center;
}

.site-layout .site-layout-background {
  background: #fff;
  margin-left: 0 !important;
}

.ant-layout.ant-layout-has-sider {
  height: 100vh !important;
}

.custom-button .ant-btn-primary {
  background-color: #47A0AF;
  border: #47A0AF;
}